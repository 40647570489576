<template>
  <div class="app-container">
    <div class="top_select">
      <div>
        <el-button
          icon="RefreshRight"
          size="small"
          style="margin-right: 5px"
          @click="getList()"
        ></el-button>
        <el-input
          size="small"
          placeholder="请输入代账名称"
          v-model.trim="listQuery.orgName"
          style="width: 200px"
          @keyup.enter="getList"
          clearable
        ></el-input>
        <el-button
          size="small"
          type="primary"
          @click="getList"
          style="margin-right: 10px"
          icon="Search"
          >搜索</el-button
        >
        <el-date-picker
          style="width: 150px"
          v-model="listQuery.day"
          type="date"
          placeholder="统计日期"
          :clearable="false"
          value-format="YYYY-MM-DD"
          size="small"
          @change="getList"
        />
      </div>
      <div>
        <el-button
          size="small"
          type="primary"
          @click="exportData"
          icon="Folder"
          :loading="btnLoading"
          >导出</el-button
        >
      </div>
    </div>
    <el-table
      :data="list"
      :height="contentStyleObj"
      border
      v-loading="loading"
      stripe
    >
      <template #empty>
        <el-empty :image-size="150" description="没有数据"></el-empty>
      </template>
      <el-table-column
        prop="branch_name"
        label="公司名称"
        min-width="200"
        fixed="left"
      >
      </el-table-column>
      <el-table-column prop="new_account_day" label="新建账套-自然日" sortable>
      </el-table-column>
      <el-table-column
        prop="tax_declaration_day"
        label="税款申报-自然日"
        sortable
      >
      </el-table-column>
      <el-table-column
        prop="zero_declaration_day"
        label="零申报-自然日"
        sortable
      >
      </el-table-column>
      <el-table-column
        prop="stopped_customer_day"
        label="停报客户-自然日"
        sortable
      >
      </el-table-column>
      <el-table-column prop="recycle_bin_day" label="回收站-自然日" sortable>
      </el-table-column>
      <el-table-column prop="total_day" label="合计-自然日" sortable>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <qzf-pagination
        v-show="total > 0"
        :total="total"
        v-model:page="listQuery.page"
        v-model:limit="listQuery.limit"
        @pagination="getList"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, getCurrentInstance } from "vue";
import { getSysComsDataList, exportComsData } from "@/api/org";
import { getPreviousDay } from "@/utils/fun";
const { proxy } = getCurrentInstance();
const list = ref([]);
const listQuery = ref({
  page: 1,
  limit: 20,
  orgName: "",
  day: getPreviousDay(),
});
const contentStyleObj = ref({});
const total = ref(0);
const loading = ref(false);
const btnLoading = ref(false);
onMounted(() => {
  contentStyleObj.value = proxy.$getHeight(210);
  getList();
});
const getList = () => {
  loading.value = true;
  getSysComsDataList(listQuery.value).then((res) => {
    loading.value = false;
    if (res.data.msg == "success") {
      list.value = res.data.data.list;
      total.value = res.data.data.total;
    }
  });
};
const exportData = () => {
  btnLoading.value = true;
  exportComsData(listQuery.value).then((res) => {
    btnLoading.value = false;
    if (res.data.msg == "success") {
      window.open(res.data.data);
    }
  });
};
</script>

<style scoped lang="scss">
.top_select {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
</style>
